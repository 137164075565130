import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { LifeCycleStatusDTO } from '../dto/life-cycle-status.dto';

@Injectable({
  providedIn: 'root',
})
export class LifeCycleStatusService {
  private apiUrl = environment.apiUrl; // Use the apiUrl from environment

  constructor(private http: HttpClient) {}

    getLifeCycleStatuses(
        sortField: string = 'title_en',
        sortOrder: string = 'asc',
        searchQuery?: string
    ): Observable<LifeCycleStatusDTO[]> {
        let params = new HttpParams()
        .set('sort_by', sortField)
        .set('sort_order', sortOrder);

        if (searchQuery) {
        params = params.set('keyword', searchQuery);
        }

        return this.http.get<LifeCycleStatusDTO[]>(this.apiUrl + '/lifecycle-statuses', {
        params,
        });
    }

  
}
